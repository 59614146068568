import React from 'react';
import styled from 'styled-components';
import {Radio} from 'antd';
import {ChevronUp} from '@styled-icons/boxicons-regular/ChevronUp';
import {ChevronRight} from '@styled-icons/boxicons-regular/ChevronRight';
import {Trash} from '@styled-icons/boxicons-regular/Trash';
import DrinkVariantDetail from './DrinkVariantDetail';
import Theme from '../../Theme';
import * as PriceUtil from '../../Utils/PriceUtil';
import {useSelectedStore} from '../SelectStoreModal/helper';

function getSelectedSetChoice({selectedSet, setVariant}) {
  if (selectedSet && selectedSet !== 'none') {
    return (setVariant?.choices || []).find((c) => c.name === selectedSet);
  }
  return null;
}

export default function DrinksVariant(props) {
  const {drinks, selected, setSelected, selectedSet, setVariant, catL1} = props;
  const [expand, setExpand] = React.useState(true);
  const [currEditingDrink, setCurrEditingDrink] = React.useState(null);
  const {product: selectedDrink, variants: selectedDrinkVariants} =
    selected || {};
  const selectedSetChoice = getSelectedSetChoice({selectedSet, setVariant});
  const store = useSelectedStore();

  return (
    <>
      <Wrapper expand={expand}>
        <div
          className="title"
          style={{backgroundColor: catL1?.midtone}}
          onClick={() => setExpand(!expand)}>
          <h2>飲料</h2>
          <div className="icon">
            <ChevronUp size={22} color={'#fff'} />
          </div>
        </div>

        <div className="content">
          {(drinks || []).map((drink) => {
            const checked = (selectedDrink && selectedDrink.id) === drink.id;
            let drinkPriceDisplay;
            let diffPrice = drink.price;

            if (selectedSetChoice) {
              diffPrice += selectedSetChoice.discount;
              drinkPriceDisplay = `+ ${diffPrice > 0 ? diffPrice : 0}`;
            } else {
              diffPrice = PriceUtil.displayPrice(diffPrice, store);
              drinkPriceDisplay = `${diffPrice}`;
            }

            return (
              <div className="item" key={drink.id}>
                <div>
                  <Radio
                    checked={checked}
                    disabled={drink.$invalid}
                    onChange={() => {
                      // setSelected(drink);
                      setCurrEditingDrink(drink);
                    }}>
                    {(drink?.images || [])[0] && (
                      <img
                        src={(drink?.images || [])[0]?.expected_url}
                        alt="variant-image"
                      />
                    )}
                    {drink.name} {drink.$invalid ? '(補貨中)' : ''}
                  </Radio>
                  <div>{drinkPriceDisplay}</div>
                </div>
                {checked && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      border: `1px solid ${Theme.colors.main}`,
                      padding: 15,
                      borderRadius: 6,
                      backgroundColor: 'rgb(251, 249, 241)',
                      margin: '8px 0',
                    }}
                    onClick={() => {
                      setCurrEditingDrink(drink);
                    }}>
                    <div
                      style={{
                        flex: 1,
                        textAlign: 'left',
                        color: '#666',
                        marginRight: 10,
                      }}>
                      {Object.keys(selectedDrinkVariants).reduce(
                        (acc, name, idx) => {
                          const variant = (drink.variants || []).find(
                            (v) => v.name === name,
                          );
                          const value = selectedDrinkVariants[name];

                          let text = '';
                          if (!variant) {
                            text = '顯示錯誤';
                          } else if (variant.type === 'single') {
                            const choice = (variant.choices || []).find(
                              (c) => c.name === value,
                            );
                            text =
                              ((variant && variant.display) || name) +
                              '/' +
                              ((choice && choice.display) || value) +
                              '/+$' +
                              (choice && choice.price > 0 ? choice.price : 0);
                          } else {
                            text =
                              ((variant && variant.display) || name) +
                              '/+$' +
                              (variant && variant.price > 0
                                ? variant.price
                                : 0);
                          }

                          if (idx === 0) {
                            return text;
                          }
                          return acc + ', ' + text;
                        },
                        '',
                      ) || '無選項'}
                    </div>

                    <div
                      style={{paddingRight: 6}}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setSelected(null);
                      }}>
                      <Trash size={22} color={Theme.colors.main} />
                    </div>

                    <ChevronRight size={22} color={Theme.colors.main} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Wrapper>

      <DrinkVariantDetail
        drink={currEditingDrink}
        selectDrink={({drink, drinkVariants}) =>
          setSelected({product: drink, variants: drinkVariants})
        }
        selectedSetChoice={selectedSetChoice}
        onClose={() => setCurrEditingDrink(null)}
      />
    </>
  );
}

const Wrapper = styled.div`
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: ${Theme.shadow};

  & > .title {
    padding: 10px 20px;
    color: pink;
    display: flex;
    align-items: center;
    & > h2 {
      flex: 1;
      color: #fff;
    }
    & > .icon {
      transition: transform 200ms;
      transform: rotate(${(props) => (props.expand ? '180deg' : '0deg')});
    }
  }

  & > .content {
    overflow: hidden;
    transition: all 200ms;
    max-height: ${(props) => (props.expand ? 'auto' : '0px')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
    background-color: ${Theme.colors.bgYellow};

    & > .item {
      padding: 12px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      & > *:first-child {
        display: flex;
        align-items: center;

        & > *:first-child {
          flex: 1;
        }

        & > *:last-child {
          font-size: 1rem;
        }
      }

      & img {
        width: 70px;
        height: 46.66px; // 2/3
        object-fit: contain;
        margin-right: 12px;
      }
    }
  }
`;
