import React from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'antd';
import BoolVariants from './BoolVariants';
import SingleVariant from './SingleVariant';
import * as CatUtil from '../../Utils/CatUtil';
import Theme from '../../Theme';

export function getDefaultDrinkVariants(drink) {
  const defaultSelectedVariants = {};
  const singleVariants = (drink.variants || []).filter(
    (v) => v.type === 'single',
  );
  for (const v of singleVariants) {
    if (Array.isArray(v.choices) && v.choices[0]?.name) {
      defaultSelectedVariants[v.name] = v.choices[0].name;
    }
  }
  return defaultSelectedVariants;
}

export default function DrinkVariantDetail(props) {
  const {drink, onClose, selectDrink, selectedSetChoice} = props;
  const [selectedVariants, setSelectedVariants] = React.useState({});
  const {catL1} = React.useMemo(() => {
    return drink ? CatUtil.getCategoryOfProduct(drink) : {};
  }, [drink]);

  React.useEffect(() => {
    if (!drink) {
      setSelectedVariants({});
    } else {
      setSelectedVariants(getDefaultDrinkVariants(drink));
    }
  }, [drink]);

  const imgUrl = (drink?.images || [])[0]?.expected_url || '/images/logo.png';

  const {singleVariants, boolVariants} = React.useMemo(() => {
    const invalidVariants = drink?.$invalidVariants || [];
    return {
      singleVariants: (drink?.variants || [])
        .filter((v) => v.type === 'single')
        .map((x) => ({
          ...x,
          choices: x.choices.map((c) => ({
            ...c,
            $invalid: invalidVariants.includes(c.name),
          })),
        })),
      boolVariants: (drink?.variants || [])
        .filter((v) => v.type === 'bool')
        .map((v) => ({...v, $invalid: invalidVariants.includes(v.name)})),
    };
  }, [drink]);

  function onConfirm() {
    selectDrink({drink, drinkVariants: selectedVariants});
    onClose();
  }

  const actualDrinkPrice =
    (drink && drink.price + (selectedSetChoice?.discount || 0)) || 0;

  return (
    <Modal
      visible={drink}
      onCancel={() => onClose()}
      bodyStyle={{padding: 0, borderRadius: 8}}
      style={{padding: 0, borderRadius: 8}}
      footer={null}
      destroyOnClose>
      <Wrapper>
        {drink && (
          <>
            <div
              className="content"
              style={{backgroundColor: catL1?.lighttone}}>
              <img src={imgUrl} alt="meal" />
              <h2>{drink.name}</h2>
              <h3 style={{fontSize: 18}}>{drink.english_name}</h3>
            </div>

            <BoolVariants
              variants={boolVariants}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              catL1={catL1}
            />

            {singleVariants.map((v) => {
              return (
                <SingleVariant
                  key={v.name}
                  variant={v}
                  selected={selectedVariants[v.name]}
                  setSelected={(selected) =>
                    setSelectedVariants({
                      ...selectedVariants,
                      [v.name]: selected,
                    })
                  }
                  actualDrinkPrice={actualDrinkPrice}
                  catL1={catL1}
                />
              );
            })}

            <div className="bottom-bar">
              <Button size="large" onClick={onClose}>
                取消
              </Button>
              <div className="gutter" style={{width: 10}} />
              <Button size="large" type="primary" onClick={onConfirm}>
                確認
              </Button>
            </div>
          </>
        )}
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  padding: 50px 20px 20px 20px;

  & > .content {
    padding: 45px 20px 20px 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    margin-bottom: 20px;
    box-shadow: ${Theme.shadow};

    & > img {
      width: 80%;
      object-fit: contain;
      align-self: center;
    }

    & > h2 {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  & .bottom-bar {
    padding: 6px;
    display: flex;
    align-items: center;
    & > *:not(.gutter) {
      flex: 1;
    }
  }
`;
