import {getOutlet, hasOutlet} from 'reconnect.js';

export function getFlattenCategories(outletKey) {
  if (!hasOutlet(outletKey)) {
    return [];
  }

  const cats = getOutlet(outletKey).getValue();
  const result = [];

  if (Array.isArray(cats)) {
    for (const c1 of cats) {
      result.push(c1);
      if (Array.isArray(c1.items)) {
        for (const c2 of c1.items) {
          result.push(c2);
          if (Array.isArray(c2.items)) {
            for (const c3 of c2.items) {
              result.push(c3);
            }
          }
        }
      }
    }
  }

  return result;
}

export function getFlattenCategoriesMap(outletKey) {
  if (!hasOutlet(outletKey)) {
    return {};
  }

  const cats = getOutlet(outletKey).getValue();
  const result = {};

  if (Array.isArray(cats)) {
    for (const c1 of cats) {
      result[c1.name] = c1;
      if (Array.isArray(c1.items)) {
        for (const c2 of c1.items) {
          result[c2.name] = c2;
          if (Array.isArray(c2.items)) {
            for (const c3 of c2.items) {
              result[c3.name] = c3;
            }
          }
        }
      }
    }
  }

  return result;
}

export function getCategoryOfProduct(product, cat) {
  //  cat = L2 name, ex: xxx-xxx
  const catName = product.labels[0] || '';
  const categories = getOutlet('categories').getValue();
  if (!cat) {
    cat = catName;
  }
  const [cat1, cat2] = cat.split('-');

  let catL1 = categories.find((c) => c.name === cat1);

  if (!catL1) {
    const promoCategories = getOutlet('promoCategories').getValue();
    catL1 = promoCategories.find((p) => p.name === cat1);
  }

  let catL2;
  if (catL1 && Array.isArray(catL1.items)) {
    catL2 = catL1.items.find((x) => x.name === cat);
  }
  return {
    catL1,
    catL2,
  };
}

export function getAllowedCatL2(store, meals) {
  const allowedL2Cats = (store?.meals || []).reduce((acc, name) => {
    const meal = (meals || []).find((m) => m.name === name);
    if (meal?.product_categories?.length > 0) {
      acc = [...acc, ...meal?.product_categories];
    }
    return acc;
  }, []);

  return allowedL2Cats.reduce((sum, cat) => {
    sum[cat] = true;
    return sum;
  }, {});
}
