import React from 'react';
import styled from 'styled-components';
import {Radio} from 'antd';
import {ChevronUp} from '@styled-icons/boxicons-regular/ChevronUp';
import * as Theme from '../../Theme';
import * as PriceUtil from '../../Utils/PriceUtil';
import {useSelectedStore} from '../SelectStoreModal/helper';
import {useOutlet} from 'reconnect.js';
import {filterAllowedProductsBySelectedStore} from '../ProductList/helper';

export default function AddonVariant(props) {
  const {selected, setSelected, allProducts, catL1} = props;
  const [expand, setExpand] = React.useState(true);
  const store = useSelectedStore();
  const [meals] = useOutlet('mealCategories');

  const products = React.useMemo(() => {
    return filterAllowedProductsBySelectedStore({
      products: allProducts,
      selectedStore: store,
      meals,
    }).filter((p) => p.is_add_on_product);
  }, [allProducts, store, meals]);

  return (
    <Wrapper expand={expand}>
      <div
        className="title"
        onClick={() => setExpand(!expand)}
        style={{backgroundColor: catL1?.midtone}}>
        <h2>加購商品</h2>
        <div className="icon">
          <ChevronUp size={22} color={'#fff'} />
        </div>
      </div>

      <div className="content">
        <div className="item">
          <div>
            <Radio
              checked={!selected}
              onChange={() => {
                setSelected(undefined);
              }}>
              無
            </Radio>
            <div>{0}</div>
          </div>
        </div>
        {products.map((v) => {
          const checked = selected?.product?.id === v.id;
          const price = PriceUtil.displayPrice(v.price, store, {
            multipleOf: v?.price_delta_multiple_of,
            hitAddonCondition: true,
            product: v,
          });
          return (
            <div className="item" key={v.name}>
              <div>
                <Radio
                  checked={checked}
                  disabled={v.$invalid}
                  onChange={() => {
                    setSelected({
                      product: v,
                      variants: {},
                    });
                  }}>
                  {(v?.images || [])[0] && (
                    <img
                      src={(v?.images || [])[0]?.expected_url}
                      alt="variant-image"
                    />
                  )}
                  {v.name} {v.$invalid ? '(補貨中)' : ''}
                </Radio>
                <div>{price}</div>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: ${Theme.shadow};

  & > .title {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    & > h2 {
      flex: 1;
      color: #fff;
    }
    & > .icon {
      transition: transform 200ms;
      transform: rotate(${(props) => (props.expand ? '180deg' : '0deg')});
    }
  }

  & > .content {
    overflow: hidden;
    transition: all 200ms;
    max-height: ${(props) => (props.expand ? '1000px' : '0px')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
    background-color: ${Theme.colors.bgYellow};

    & > .item {
      padding: 12px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      & > *:first-child {
        display: flex;
        align-items: center;

        & > *:first-child {
          flex: 1;
        }

        & > *:last-child {
          font-size: 1rem;
        }
      }

      & img {
        width: 70px;
        height: 46.66px; // 2/3
        object-fit: contain;
        margin-right: 12px;
      }
    }
  }
`;
