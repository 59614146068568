import React from 'react';
import styled from 'styled-components';
import {Radio} from 'antd';
import {ChevronUp} from '@styled-icons/boxicons-regular/ChevronUp';
import Theme from '../../Theme';
import * as PriceUtil from '../../Utils/PriceUtil';
import {useSelectedStore} from '../SelectStoreModal/helper';

export default function SingleVariant(props) {
  const {variant, selected, setSelected, actualDrinkPrice, catL1} = props;
  const [expand, setExpand] = React.useState(true);
  const store = useSelectedStore();

  return (
    <Wrapper expand={expand}>
      <div
        className="title"
        style={{backgroundColor: catL1?.midtone}}
        onClick={() => setExpand(!expand)}>
        <h2>{variant.label}</h2>
        <div className="icon">
          <ChevronUp size={22} color={'#fff'} />
        </div>
      </div>

      <div className="content">
        {(variant.choices || []).map((opt) => {
          const checked = selected === opt.name;

          let diffPrice = opt.price;
          if (actualDrinkPrice < 0) {
            diffPrice = opt.price + actualDrinkPrice;
          }
          diffPrice = PriceUtil.displayPrice(diffPrice, store, {
            multipleOf: opt?.price_delta_multiple_of,
          });
          diffPrice = diffPrice > 0 ? diffPrice : 0;

          return (
            <div className="item" key={opt.name}>
              <div>
                <Radio
                  disabled={opt.$invalid}
                  checked={checked}
                  onChange={() => {
                    if (!checked) {
                      setSelected(opt.name);
                    }
                  }}>
                  {opt.image && <img src={opt.image} alt="variant-image" />}
                  {opt.label} {opt.$invalid ? '(補貨中)' : ''}
                </Radio>
                <div>+ {diffPrice}</div>
              </div>

              {/*checked && variant.name === 'set' && opt.name !== 'none' && (
                <div
                  style={{
                    marginTop: 5,
                    textAlign: 'right',
                  }}>
                  {`套餐飲料折價/${opt.discount}`}
                </div>
                )*/}
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: ${Theme.shadow};

  & > .title {
    padding: 10px 20px;
    color: pink;
    display: flex;
    align-items: center;
    & > h2 {
      flex: 1;
      color: #fff;
    }
    & > .icon {
      transition: transform 200ms;
      transform: rotate(${(props) => (props.expand ? '180deg' : '0deg')});
    }
  }

  & > .content {
    overflow: hidden;
    transition: all 200ms;
    max-height: ${(props) => (props.expand ? '1000px' : '0px')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
    background-color: ${Theme.colors.bgYellow};

    & > .item {
      padding: 12px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      & > *:first-child {
        display: flex;
        align-items: center;

        & > *:first-child {
          flex: 1;
        }

        & > *:last-child {
          font-size: 1rem;
        }
      }

      & img {
        width: 70px;
        height: 46.66px; // 2/3
        object-fit: contain;
        margin-right: 12px;
      }
    }
  }
`;
