import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import ProductDetail from '../../Components/ProductDetail';
import {useSelectedStore} from '../../Components/SelectStoreModal';

export default function ProductDetailPage(props) {
  const [variantGroups] = useOutlet('variantGroups');
  const [cart] = useOutlet('LayaCart');
  const {id, cartItemIdx: _cartItemIdx, cat} =
    typeof window === 'undefined' ? {} : qs.parse(window.location?.search);
  const [product, setProduct] = React.useState(null);
  const [allProducts, setAllProducts] = React.useState(null);
  const store = useSelectedStore();

  React.useEffect(() => {
    async function fetchData() {
      try {
        if (id && store) {
          // check store for invalid products
          const allProducts = await AppActions.fetchProducts();
          setAllProducts(allProducts);
          setProduct(allProducts.find((p) => p.id === id));
        }
      } catch (ex) {}
    }

    if (variantGroups.length > 0) {
      fetchData();
    }
  }, [id, variantGroups, store]);

  let cartItemProps = {};
  let cartItemIdx = parseInt(_cartItemIdx);

  if (!isNaN(cartItemIdx)) {
    const cartItem = (cart?.items || [])[cartItemIdx];
    if (cartItem?.product?.id === id) {
      // valid cart item, let the user to edit cart
      cartItemProps = {
        cartItemIdx,
        cartItem,
      };
    }
  }

  return (
    <Wrapper>
      <div className="content">
        {product && (
          <ProductDetail
            product={product}
            allProducts={allProducts}
            {...cartItemProps}
            cat={cat}
          />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 150px;
  min-height: 80vh;

  & > .content {
    border-radius: 6px;
    max-width: 720px;
    margin: 0 auto;
    overflow: hidden;
  }

  @media (max-width: 480px) {
    padding-top: 50px;
  }
`;
