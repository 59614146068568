import React from 'react';
import * as ProductUtil from '../../Utils/ProductUtil';
import Theme from '../../Theme';

export function ProductAttrTags(props) {
  const {product} = props;
  const attributes = ProductUtil.getAttributes(product);
  return attributes.map((attr) => {
    return (
      <div
        key={attr.name}
        style={{
          ...Theme.tag,
          ...{borderColor: attr.color || 'rgb(121, 201, 158)'},
        }}>
        <div style={{color: attr.color}}>{attr.name}</div>
      </div>
    );
  });
}

export function ProductPromoTags(props) {
  const {product} = props;
  const promotions = ProductUtil.getPromotions(product);

  return promotions.map((promoCat) => {
    return (
      <div
        key={promoCat.name}
        style={{
          ...Theme.tag,
          ...{borderColor: promoCat.color || 'rgb(226, 104, 0)'},
        }}>
        <div style={{color: promoCat.color || 'rgb(226, 104, 0)'}}>
          {promoCat.item_display || promoCat.display}
        </div>
      </div>
    );
  });
}

export function ProductHashTags(props) {
  const {product} = props;
  const hashtags = ProductUtil.getHashtags(product);
  return hashtags.map((hashtag) => {
    return (
      <div
        key={hashtag.name}
        style={{...Theme.tag, borderColor: Theme.colors.main}}>
        <div style={{color: Theme.colors.main}}>{hashtag.name}</div>
      </div>
    );
  });
}
