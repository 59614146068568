import React from 'react';
import styled from 'styled-components';
import {Checkbox} from 'antd';
import {ChevronUp} from '@styled-icons/boxicons-regular/ChevronUp';
import Theme from '../../Theme';
import * as PriceUtil from '../../Utils/PriceUtil';
import {useSelectedStore} from '../SelectStoreModal/helper';

export default function BoolVariants(props) {
  const {variants, catL1} = props;

  if (variants.length === 0) {
    return null;
  }

  const groups = variants.reduce((acc, v) => {
    const groupName = v.group || '其他選項';
    const group = acc.find((g) => g.name === groupName);
    if (group) {
      group.items.push(v);
    } else {
      acc.push({
        name: groupName,
        items: [v],
      });
    }
    return acc;
  }, []);

  return groups.map((group, idx) => {
    return (
      <BoolVariantGroup
        key={idx}
        {...props}
        title={group.name}
        variants={group.items}
        catL1={catL1}
      />
    );
  });
}

function BoolVariantGroup(props) {
  const {selectedVariants, setSelectedVariants, variants, title, catL1} = props;
  const [expand, setExpand] = React.useState(true);
  const store = useSelectedStore();

  if (variants.length === 0) {
    return null;
  }

  return (
    <Wrapper expand={expand}>
      <div
        className="title"
        onClick={() => setExpand(!expand)}
        style={{backgroundColor: catL1?.midtone}}>
        <h2>{title}</h2>
        <div className="icon">
          <ChevronUp size={22} color={'#fff'} />
        </div>
      </div>

      <div className="content">
        {variants.map((v) => {
          const checked = selectedVariants[v.name];
          let price = PriceUtil.displayPrice(v.price, store, {
            multipleOf: v?.price_delta_multiple_of,
          });
          return (
            <div key={v.name}>
              <Checkbox
                checked={checked}
                disabled={v.$invalid}
                onChange={() => {
                  const nextVariants = {...selectedVariants};
                  if (checked) {
                    delete nextVariants[v.name];
                  } else {
                    nextVariants[v.name] = true;
                  }
                  console.log('checked!!');
                  setSelectedVariants(nextVariants);
                }}>
                {v.image && <img src={v.image} alt="variant-image" />}
                {v.label} {v.$invalid ? '(補貨中)' : ''}
              </Checkbox>
              <div>
                {price >= 0 ? '+' : '-'} {price}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 14px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: ${Theme.shadow};

  & > .title {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    & > h2 {
      flex: 1;
      color: #fff;
    }
    & > .icon {
      transition: transform 200ms;
      transform: rotate(${(props) => (props.expand ? '180deg' : '0deg')});
    }
  }

  & > .content {
    overflow: hidden;
    transition: all 200ms;
    max-height: ${(props) => (props.expand ? '1000px' : '0px')};
    opacity: ${(props) => (props.expand ? 1 : 0)};
    background-color: ${Theme.colors.bgYellow};

    & > * {
      display: flex;
      align-items: center;
      padding: 12px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      & > *:first-child {
        flex: 1;
      }

      & > *:last-child {
        font-size: 1rem;
      }

      & img {
        width: 70px;
        height: 46.66px; // 2/3
        object-fit: contain;
        margin-right: 12px;
      }
    }
  }
`;
