export function filterAllowedProductsBySelectedStore({
  products,
  selectedStore,
  meals,
}) {
  if (!selectedStore) {
    return products;
  }

  const allowedMealList = meals.filter((c) => {
    return (selectedStore?.meals || []).indexOf(c.name) > -1;
  });

  const allowedCats = {};
  for (const meal of allowedMealList) {
    for (const _cat of meal.product_categories || []) {
      allowedCats[_cat] = true;
    }
  }

  const allowedProducts = [];
  for (const product of products) {
    for (const label of product.labels || []) {
      if (allowedCats[label]) {
        allowedProducts.push(product);
        break;
      }
    }
  }

  return allowedProducts;
}

export function filterExternalProducts({products, meals}) {
  const allowedMealList = meals.filter(
    (m) =>
      m.name.toUpperCase().indexOf('UBEREAT') > -1 ||
      m.name.toUpperCase().indexOf('FOODPANDA') > -1,
  );

  const allowedCats = {};
  for (const meal of allowedMealList) {
    for (const _cat of meal.product_categories || []) {
      allowedCats[_cat] = true;
    }
  }

  const allowedProducts = [];
  for (const product of products) {
    for (const label of product.labels || []) {
      if (allowedCats[label]) {
        allowedProducts.push(product);
        break;
      }
    }
  }

  return allowedProducts;
}
