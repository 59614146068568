import {getOutlet} from 'reconnect.js';
import {getFlattenCategoriesMap} from './CatUtil';

export function getAttributes(product) {
  if (product) {
    const productAttrs = getOutlet('product_attribute').getValue();

    if (Array.isArray(product?.attributes)) {
      return product.attributes.map((attrName) => {
        const attr = productAttrs.find((att) => attrName === att.name);
        return attr
          ? attr
          : {
              name: attrName,
              color: '#888888',
            };
      });
    }
  }

  return [];
}

export function getHashtags(product) {
  if (product) {
    const hashtags = getOutlet('hotsearch').getValue();

    if (Array.isArray(product?.hashtags)) {
      return product.hashtags.map((tagName) => {
        const tag = hashtags.find((t) => tagName === t.name);
        return tag
          ? tag
          : {
              name: tagName,
              color: '#888888',
            };
      });
    }
  }

  return [];
}

export function getPromotions(product) {
  const promoCats = getFlattenCategoriesMap('promoCategories');
  const result = [];

  if (product && Array.isArray(product.promotions)) {
    for (const name of product.promotions) {
      const promoCat = promoCats[name];
      if (promoCat) {
        result.push(promoCat);
      }
    }
  }

  return result;
}
